import React from 'react'

type Props = {}

function ContactUs({}: Props) {
  return (
    <div>
        <h1>Contact</h1>
        <p>Feel Free to contact us</p>
    </div>
  )
}

export default ContactUs